import * as React from 'react'
import { Modal } from 'react-bootstrap'
import Editable from './Editable';
import styles from './UpdateOrder.module.scss'

interface iProp {
  open: boolean;
  onHide: () => void;
  shippingTable: any;
  setShippingTable: any;
}

const UpdateItemShippingCostModal: React.FC<iProp> = (props): JSX.Element => {

  const rows = Object.values(props.shippingTable)

  const handleChange = (v: any, e: boolean, altCode: string) => {
    props.setShippingTable((prev: any) => {
      const clone = { ...prev }
      clone[altCode].value = v
      clone[altCode].edited = e
      return clone
    })
  }

  return (
    <Modal
      className="custom-modal nestedModalL1"
      show={props.open}
      onHide={props.onHide}
      aria-labelledby="custom-modal"
      backdrop="static"
      centered={true}
      size='lg'
      keepMounted
      backdropClassName='nestedBackdropL1'
    >
      <Modal.Header>
        <h5>Update Shipping Rate</h5>
      </Modal.Header>
      <Modal.Body>
        <div style={{ width: '100%' }} >
          <table className='table'>
            <thead>
              <tr>
                <th className={styles.sp_td_sr}>#</th>
                <th className={styles.sp_td}>Alt Code</th>
                <th className={styles.sp_td}>Shipping</th>
              </tr>
            </thead>
            <tbody>
              {rows?.map((item: any, i: number) => (
                <tr>
                  <td className={styles.sp_td_sr}>{i + 1}</td>
                  <td className={styles.sp_td}>{item.altCode}</td>
                  <td className={styles.sp_td}>
                    <Editable
                      defaultValue={item.defaultValue}
                      value={item.value}
                      onChange={(v: any, e: boolean) => handleChange(v, e, item.altCode)}
                      edited={item.edited}
                      style={{ width: '70px' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={props.onHide}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal >
  )
}

export default UpdateItemShippingCostModal
