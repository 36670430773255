import * as React from 'react'
import { Col, Row, Stack } from 'react-bootstrap'
import styles from './UpdateOrder.module.scss'
import { Controller, useFormContext } from 'react-hook-form'
const SIZES = ['XS', 'S', 'M', 'L', 'XL', 'XXL']

const ItemQtyBox = ({ size, avail, needed, totalQty, onChange, fieldName }) => {
    const { control } = useFormContext()
    return (
      <Stack style={{ textAlign: "center", width: "50px" }}>
        <span style={{ fontSize: "12px" }}>{size}</span>
        <Stack style={{ border: "1px solid black" }}>
          {/* <input
                    placeholder='0'
                    className={styles.sizeQtyInput}
                    value={needed}
                    onChange={(e) => {
                        if (/^[0-9]*$/.test(e.target.value)) {
                            onChange(e.target.value.slice(0, 5))
                        }
                    }}
                /> */}
          <Controller
            control={control}
            name={fieldName}
            render={({ field: { value, onChange, ref } }) => (
              <input
                placeholder="0"
                className={styles.sizeQtyInput}
                value={value}
                onChange={(e) => {
                  if (/^[0-9]*$/.test(e.target.value)) {
                    onChange(e.target.value.slice(0, 5));
                  }
                }}
                ref={ref}
              />
            )}
          />
          <span style={{ fontSize: "12px", color: "grey" }}>{avail}</span>
        </Stack>
        <span style={{ fontSize: "12px", color: "grey" }}>{totalQty}</span>
      </Stack>
    );
}

const UpdateOrderItem = ({ item, variantQty, quantities, setQuantities, itemStats, poIndex, itemIndex }) => {
    const { control, watch, setValue } = useFormContext()
    const quantity = watch(`purchaseOrders.${poIndex}.orderItems.${itemIndex}.quantity`)
    const [xs, s, m, l, xl, xxl] = watch(SIZES.map(size => `purchaseOrders.${poIndex}.orderItems.${itemIndex}.skuQty${size}`))
    React.useMemo(() => {
        const quantity = [xs, s, m, l, xl, xxl].reduce((a, c) => {
            const n = Number(c)
            if(typeof n === 'number') a += n
            return a
        }, 0)
        setValue(`purchaseOrders.${poIndex}.orderItems.${itemIndex}.quantity`, quantity, { shouldValidate: true })
    }, [xs, s, m, l, xl, xxl])
    const handleSizeQtyChange = (size, value) => {
        setValue(`quantities.${item.id}.${size}`, value, { shouldDirty: true })
        setQuantities((prev) => {
            const clone = { ...prev }
            clone[item.id][size].value = value
            return clone
        })
    }

    return (
        <tr style={{ marginBottom: '10px' }}>
            <td width='35%'>
                <Stack direction='horizontal'>
                    <img width='80px' height='auto' src={item.imageUrl} />
                    <div className='d-flex justify-content-center align-items-center ms-3' >
                        <Stack>
                            <p className='m-0' >
                                <span>{item.altCode} - {item.b2bWebTitle}</span>
                                <br />
                                <span className={styles.itemColorGraphic}>{item.bodyColorName} ({item.apparelNotes})</span>
                            </p>
                        </Stack>
                    </div>
                </Stack>
            </td>
            <td style={{ width: '35%', textAlign: 'center' }}>
                <Row>
                    {['XS', 'S', 'M', 'L', 'XL', 'XXL'].map((size) => (
                        <Col key={size} xs={2}>
                            <ItemQtyBox
                                fieldName={`purchaseOrders.${poIndex}.orderItems.${itemIndex}.skuQty${size}`}
                                size={size}
                                avail={'x'}
                                needed={quantities[item.id][size].value}
                                handleSku={(value) => handleSizeQtyChange(size, value)}
                                totalQty={variantQty?.[size]}
                            />
                        </Col>
                    ))}
                </Row>
            </td>
            <td style={{ width: '15%', textAlign: 'center' }}>{quantity}</td>
            <td style={{ width: '15%', textAlign: 'center' }}>${itemStats.price.value}</td>
            <td style={{ width: '15%', textAlign: 'center' }}>${itemStats.shipping.value}</td>
        </tr>
    )
}

export default UpdateOrderItem