import axios from "axios";
import { BASE_URL } from "./apiConstants";
import { client } from "utils/client";

export const getAllOrders = (token: string, page: number = 1, limit?: number): Promise<any> => {
  return client.get(`/orders?page=${page}&fields=purchaseOrders,childInfo,userInfo,parentInfo&limit=5000`)
  // return axios({
  //   method: "get",
  //   url:
  //     BASE_URL +
  //     `/orders?page=${page}&fields=purchaseOrders,childInfo,userInfo,parentInfo&limit=5000`,
  //     //  +
  //     // (limit ? `&limit=${0}` : ""),
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
}


export const getSingleOrder = (id: number, token: string, page: number = 1, limit?: number) => {
  return client.get(`/orders/${id}?page=${page}&fields=purchaseOrders,parentInfo,userInfo,paymentInfo&limit=5000`+(limit ? `&limit=${limit}` : ""))
  // return axios({
  //   method: "get",
  //   url: BASE_URL + `/orders/${id}?page=${page}&fields=purchaseOrders,parentInfo,userInfo,paymentInfo&limit=5000`+
  //   (limit ? `&limit=${limit}` : ""),
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};

export const deleteOrder = (id: number, token: string) => {
  return client.delete(`/orders/${id}`)
  // return axios({
  //   method: "delete",
  //   url: BASE_URL + `/orders/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};

export const downloadOrderInfoCSV = (data: any, token: string) => {
  return client.post('/orders/order-item-csv', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/orders/order-item-csv",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const sendBOMEmail = (
  token: string
) => {
  return client.post('/events/trigger', { eventName: 'MAIL_ORDER_CSV' })
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/events/trigger',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data: { eventName: 'MAIL_ORDER_CSV' },
  // });
};

export const startOrderSyncToAM = (
  token: string
) => {
  return client.post('/events/trigger', { eventName: 'SYNC_ORDER_TO_AM' })
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/events/trigger',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data: { eventName: 'SYNC_ORDER_TO_AM' },
  // });
};

export const checkOrderSyncStatus = (
  token: string
) => {
  const eventName = 'SYNC_ORDER_TO_AM'
  return client.get(`/events/status/${eventName}`)
  // return axios({
  //   method: 'get',
  //   url: BASE_URL + '/events/status/' + eventName,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   }
  // });
};

export const checkBOMEmailStatus = (
  token: string
) => {
  const eventName = 'MAIL_ORDER_CSV'
  return client.get(`/events/status/${eventName}`)
  // return axios({
  //   method: 'get',
  //   url: BASE_URL + '/events/status/' + eventName,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   }
  // });
};

export const fetchAllOrders = (token: string, page: number = 1, limit?: number): Promise<any> => {
  return client.get(`/orders?page=${page}&fields=purchaseOrders,childInfo,userInfo,parentInfo&limit=500&syncFailedOnly=1`)
}
  // axios({
  //   method: "get",
  //   url:
  //     BASE_URL +
  //     `/orders?page=${page}&fields=purchaseOrders,childInfo,userInfo,parentInfo&limit=500&syncFailedOnly=1`,
  //     //  +
  //     // (limit ? `&limit=${0}` : ""),
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });

  export const updateOrder = (token: string, orderId: number, payload: any): Promise<any> => {
    return client.put(`/orders/${orderId}`, payload)
  }
  // axios({
  //   method: "put",
  //   url: BASE_URL + `/orders/${orderId}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data: payload
  // });