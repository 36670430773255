import * as React from 'react'
import { useFormContext } from 'react-hook-form'

interface IProp {
  data: any;
  templatePricing: any;
  shippingRate: any
}

const SIZES = ['XS', 'S', 'M', 'L', 'XL', 'XXL']

const Calculation: React.FC<IProp> = ({ data, templatePricing, shippingRate }): null => {
  const { watch, reset } = useFormContext()
  
  const quantities = watch('quantities')

  React.useEffect(() => {
    const _itemsQty: any = {}
    const _templatesQty: any = {}
    const __templatesPricing: any = {}

    data?.purchaseOrders.forEach((po: any) => {
      po.orderItems.forEach((item: any) => {
        const _qty = SIZES.reduce((a: number, c: string) => {
          const skuQty = Number(quantities?.[item.id]?.[c]);
          a += isNaN(skuQty) ? 0 : skuQty;
          return a;
        }, 0);
        _itemsQty[item.id] = _qty
        _templatesQty[item.templateId] = (_templatesQty[item.templateId] || 0) + _qty;
      });
    });

    for (let templateId in _templatesQty) {
      const qty = _templatesQty[templateId];
      if (templatePricing[templateId].baseQty <= qty) {
        __templatesPricing[templateId] = templatePricing[templateId].forQty(qty);
      }
    }
    const itemStats: any = {}
    const poStats: any = {}
    const stats = { amountDiscount: 0, amountSubTotal: 0, amountShipping: 0, amountTotal: 0 }
    data?.purchaseOrders.forEach((po: any) => {
      po.orderItems.forEach((item: any) => {
        const _qty = _itemsQty[item.id];
        const _pricing = __templatesPricing[item.templateId] || { qty: 0, price: 0 };
        const _price = Number(((_qty / _pricing.qty) * _pricing.price).toFixed(2));
        const _saving = _pricing.hasSaving ? Number(((_qty / _pricing.qty) * _pricing.saving).toFixed(2)): 0;
        const _shipping = Number((shippingRate[item.templateId] * _qty).toFixed(2));
        if(!poStats[po.id]) poStats[po.id] = { amountDiscount: 0, amountSubTotal: 0, amountShipping: 0, amountTotal: 0 }
        const amountDiscount = _saving
        const amountSubTotal = _price
        const amountShipping = _shipping
        const amountTotal = Number((_price + _shipping).toFixed(2))
        itemStats[item.id] = { _qty, _price, _saving, _shipping }
        poStats[po.id].amountSubTotal += amountSubTotal 
        poStats[po.id].amountDiscount += amountDiscount 
        poStats[po.id].amountShipping += amountShipping 
        poStats[po.id].amountTotal += amountTotal 
        stats.amountSubTotal = amountSubTotal
        stats.amountDiscount = amountDiscount
        stats.amountShipping = amountShipping
        stats.amountTotal = amountTotal
      });
    });

    const fieldToUpdate: any = {}
    data?.purchaseOrders.forEach((po: any, poIndex: number) => {
      po.orderItems.forEach((item: any, itemIndex: number) => {
        fieldToUpdate[`purchaseOrders.${poIndex}.amountDiscount`] = poStats[po.id].amountDiscount
        fieldToUpdate[`purchaseOrders.${poIndex}.amountSubTotal`] = poStats[po.id].amountSubTotal
        fieldToUpdate[`purchaseOrders.${poIndex}.amountShipping`] = poStats[po.id].amountShipping
        fieldToUpdate[`purchaseOrders.${poIndex}.amountTotal`] = poStats[po.id].amountTotal
      })
    });

    reset(fieldToUpdate)

    // console.log(itemStats, poStats, stats)
  }, [quantities])

  return null
}

export default Calculation