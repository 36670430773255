import * as React from 'react'

const Editable = ({ defaultValue, value, onChange, edited = false, ...props }) => {
    const [edit, setEdit] = React.useState(edited)

    const handleToggle = () => {
        setEdit((prev) => {
            if (prev) onChange(defaultValue, false)
            return !prev
        })
    }

    return (
        <>

            {edit ? (
                <>
                    <input
                        className='ms-1'
                        value={value}
                        onChange={(e) => onChange(e.target.value, true)}
                        {...props}
                    />
                    <button type='button' className='icon-btn' onClick={handleToggle}>
                        <i className='fa-solid fa-xmark text-danger'></i>
                    </button>
                </>
            ) : (
                <>
                    <span>{value}</span>
                    <button type='button' className='icon-btn' onClick={handleToggle}>
                        <i className='fa fa-pencil text-danger'></i>
                    </button>
                </>
            )}
        </>

    )
}

export default Editable