import * as React from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionContext,
  Card,
  Modal,
  useAccordionButton,
} from "react-bootstrap";

import { IUserDetails } from "types/UserSlice";
import { getUserDetails } from "store/User.slice";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import * as addressService from "api/addressService";
import { getSingleOrder, updateOrder } from "api/orderService";
import { fetchAllTemplatePricingByIdsService } from "api/templateService";

import UpdateOrderSummary from "./UpdateOrderSummary";
import UpdatePurchaseOrder from "./UpdatePurchaseOrder";
import UpdateItemPriceModal from "./UpdateItemPriceModal";
import { SHIPPING_TABLE, generatePricing } from "./UpdateOrder.utils";
import UpdateItemShippingCostModal from "./UpdateItemShippingCostModal";
import { FormProvider, useForm } from "react-hook-form";
import DefaultValueProvider from "./DefaultValueContext";
import Calculation from "./Calculation";

const SIZES = ["XS", "S", "M", "L", "XL", "XXL"];

interface iProps {
  id: any;
  orderNumber: string;
  open: boolean;
  onHide: () => any;
  setRefetch: any;
}
interface OrderItem {
  id: number;
  templateId: number;
  skuQtyXS: number,
  skuQtyS: number,
  skuQtyM: number,
  skuQtyL: number,
  skuQtyXL: number,
  skuQtyXXL: number,
  quantity: number,
  oldPrice: number,
  price: number,
}
interface PurchaseOrder {
  id: number;
  PO: string;
  addressId: number;
  orderItems: OrderItem[];
  amountSubTotal: number;
  amountDiscount: number;
  amountShipping: number;
  amountTotal: number;
}
interface FormValues {
  id: number;
  purchaseOrders: PurchaseOrder[];
  amountSubTotal: number;
  amountDiscount: number;
  amountShipping: number;
  amountTotal: number;
  quantities: {[key: string]: { [key: string]: number } }
  itemToBlank: {[key: string]: string }
}

interface iPOHeader {
  eventKey: string;
  PO: string;
  openPriceModal: () => void;
  openShippingModal: () => void;
}

const POHeader: React.FC<iPOHeader> = ({
  eventKey,
  PO,
  openPriceModal,
  openShippingModal,
}): JSX.Element => {
  const { activeEventKey } = React.useContext(AccordionContext);

  const toggleBtn = useAccordionButton(eventKey, () =>
    console.log("Custom Button")
  );

  return (
    <div
      style={{ width: "100%", paddingRight: "10px" }}
      className="d-flex justify-content-between align-items-center"
    >
      <div>
        PO: <span>{PO}</span>
      </div>
      <div>
        <span
          onClick={openPriceModal}
          style={{
            display: "inline-block",
            cursor: "pointer",
            marginRight: "20px",
          }}
        >
          <i className="fa-solid fa-money-bill text-danger"></i>
        </span>
        <span
          onClick={openShippingModal}
          style={{
            display: "inline-block",
            cursor: "pointer",
            marginRight: "20px",
          }}
        >
          <i className="fa-solid fa-truck-fast text-danger"></i>
        </span>
        <span
          onClick={toggleBtn}
          style={{ display: "inline-block", cursor: "pointer" }}
        >
          {activeEventKey === eventKey ? (
            <i className="fa-solid fa-chevron-up"></i>
          ) : (
            <i className="fa-solid fa-chevron-down"></i>
          )}
        </span>
      </div>
    </div>
  );
};

const UpdateOrder: React.FC<iProps> = ({
  id,
  orderNumber,
  open,
  onHide,
  setRefetch,
}): JSX.Element => {
  const methods = useForm<FormValues>({
    defaultValues: {
      purchaseOrders: [],
      quantities: {},
      itemToBlank: {}
    },
  });
  const { accessToken } = useSelector(getUserDetails) as IUserDetails;
  const navigate = useNavigate();
  const [data, setData] = React.useState<any>(null);
  const [defaultValues, setDefaultValues] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [payload, setPayload] = React.useState<any>({});
  const [templatePricing, setTemplatePricing] = React.useState<any>(null);
  const [quantities, setQuantities] = React.useState<any>(null);
  const [shippingRate, setShippingRate] = React.useState<any>(null);
  const [itemStats, setItemStats] = React.useState<any>(null);
  const [poStats, setPOStats] = React.useState<any>(null);
  const [POAndAddr, setPOAndAddr] = React.useState<any>(null);
  const [shippingAddrs, setShippingAddrs] = React.useState<any[]>([]);
  const [orderStats, setOrderStats] = React.useState<any>(null);
  const [variantQty, setVariantQty] = React.useState<any>(null);

  const [priceModal, setPriceModal] = React.useState(false);
  const [shippingModal, setShippingModal] = React.useState(false);
  const [priceTable, setPriceTable] = React.useState<any>({});
  const [shippingTable, setShippingTable] = React.useState<any>({});

  const togglePriceModal = () => setPriceModal((prev) => !prev);
  const toggleShippingModal = () => setShippingModal((prev) => !prev);


  React.useEffect(() => {
    const _itemsQty = {} as any;
    const _itemStats = { ...itemStats } as any;
    const _templatesQty = {} as any;
    const _variantsQty = {} as any;
    const _templatesPricing = {} as any;

    data?.purchaseOrders.forEach((po: any) => {
      po.orderItems.forEach((item: any) => {
        const k = `${item.blankGarmentStyleNumber}_${item.bodyColorAbbr}`;
        if (!_variantsQty[k]) _variantsQty[k] = {};
        SIZES.forEach(
          (s) =>
            (_variantsQty[k][s] =
              Number(_variantsQty[k][s] || 0) + Number(item[`skuQty${s}`]))
        );
      });
    });

    data?.purchaseOrders.forEach((po: any) => {
      po.orderItems.forEach((item: any) => {
        const _qty = SIZES.reduce((a: number, c: string) => {
          const skuQty = Number(quantities[item.id][c].value);
          a += isNaN(skuQty) ? 0 : skuQty;
          return a;
        }, 0);
        _itemsQty[item.id] = _qty;
        _templatesQty[item.templateId] =
          (_templatesQty[item.templateId] || 0) + _qty;
      });
    });

    for (let templateId in _templatesQty) {
      const qty = _templatesQty[templateId];
      if (templatePricing[templateId].baseQty <= qty) {
        _templatesPricing[templateId] = templatePricing[templateId].forQty(qty);
      }
    }

    data?.purchaseOrders.forEach((po: any) => {
      po.orderItems.forEach((item: any) => {
        const _qty = _itemsQty[item.id];
        const _stat = _templatesPricing[item.templateId] || {
          qty: 0,
          price: 0,
        };
        const _price = Number(
          ((_itemsQty[item.id] / _stat.qty) * _stat.price).toFixed(2)
        );
        const _saving = _stat.hasSaving
          ? Number(((_itemsQty[item.id] / _stat.qty) * _stat.saving).toFixed(2))
          : 0;
        const _shipping = Number(
          (shippingRate[item.templateId] * _qty).toFixed(2)
        );
        _itemStats[item.id].qty.value = _qty;
        if (!_itemStats[item.id].price.edited)
          _itemStats[item.id].price.value = _price;
        if (!_itemStats[item.id].price.edited)
          _itemStats[item.id].saving.value = _saving;
        if (!_itemStats[item.id].shipping.edited)
          _itemStats[item.id].shipping.value = _shipping;
      });
    });
    setItemStats(_itemStats);
    setVariantQty(_variantsQty);
  }, [quantities, templatePricing, shippingRate]);

  React.useEffect(() => {
    const _poStats = {} as any;
    const _orderStats = {
      amountSubTotal: 0,
      amountDiscount: 0,
      amountShipping: 0,
      amountTotal: 0,
    };

    data?.purchaseOrders.forEach((po: any) => {
      _poStats[po.id] = {
        amountSubTotal: 0,
        amountDiscount: 0,
        amountShipping: 0,
        amountTotal: 0,
      };
      po.orderItems.forEach((item: any) => {
        const _price = itemStats[item.id].price?.value || 0;
        const _discount = itemStats[item.id].saving?.value || 0;
        const _shipping = itemStats[item.id].shipping?.value || 0;

        _poStats[po.id].amountSubTotal += _price;
        _poStats[po.id].amountDiscount += _discount;
        _poStats[po.id].amountShipping += _shipping;
        _poStats[po.id].amountTotal += _price + _shipping;

        _orderStats.amountSubTotal += _price;
        _orderStats.amountDiscount += _discount;
        _orderStats.amountShipping += _shipping;
        _orderStats.amountTotal += _price + _shipping;
      });
    });
    setPOStats(_poStats);
    setOrderStats(_orderStats);
  }, [itemStats]);

  React.useEffect(() => {
    const _templatePricing = {} as any;
    for (const templateId in priceTable) {
      const dozenWisePrice = priceTable[templateId].prices;
      _templatePricing[templateId] = generatePricing({ dozenWisePrice });
    }
    setTemplatePricing(_templatePricing);
  }, [data, priceTable]);

  React.useEffect(() => {
    const _shippingRate = {} as any;
    data?.purchaseOrders.forEach((po: any) =>
      po.orderItems.forEach(({ templateId, blankGarmentStyleNumber }: any) => {
        const styleAltCode = blankGarmentStyleNumber.match(/^U[0-9]{3}/i)[0];
        const shippingCost = shippingTable[styleAltCode];
        if (!_shippingRate[templateId] && shippingCost) {
          _shippingRate[templateId] = Number(shippingCost.value);
        }
      })
    );
    setShippingRate(_shippingRate);
  }, [shippingTable]);

  // console.log(itemStats, variantQty)

  React.useEffect(() => {
    if (!id) return;
    const getData = async () => {
      try {
        setLoading(true);
        const _priceTable = {} as any;
        const _shippingTable = {} as any;
        const _shippingRate = {} as any;
        const _POAndAddr = {} as any;
        const _data = await getSingleOrder(+id, accessToken).then(
          (res) => res?.data.data
        );
        const _shippingAddrs = await addressService
          .fetchShipping(accessToken, _data.parentId)
          .then((res) => res?.data.data);

        _data?.purchaseOrders.forEach((po: any) => {
          _POAndAddr[po.id] = {
            PO: po.PO,
            addressId: po.addressId,
            address1: po.address1,
            address2: po.address2,
            city: po.city,
            state: po.state,
            country: po.country,
            postalCode: po.postalCode,
          };
        });
        _data?.purchaseOrders.forEach((po: any) =>
          po.orderItems.forEach(
            ({
              templateId,
              templateName,
              altCode,
              b2bWebTitle,
              blankGarmentStyleNumber,
            }: any) => {
              const styleAltCode =
                blankGarmentStyleNumber.match(/^U[0-9]{3}/i)[0];
              if (!_priceTable[templateId]) {
                _priceTable[templateId] = {
                  templateId,
                  templateName,
                  altCode,
                  b2bWebTitle,
                };
              }
              if (!_shippingTable[styleAltCode]) {
                _shippingTable[styleAltCode] = (SHIPPING_TABLE as any)[
                  styleAltCode
                ] || {
                  edited: false,
                  altCode: styleAltCode,
                  value: 0.75,
                  defaultValue: 0.75,
                };
              }
              if (!_shippingRate[templateId]) {
                _shippingRate[templateId] = _shippingTable[styleAltCode].value;
              }
            }
          )
        );
        const templateIds = Object.keys(_priceTable).map((k) => Number(k));
        const prices = await fetchAllTemplatePricingByIdsService(
          accessToken,
          templateIds
        ).then((response) => response.data.data);
        const _groupedPrices = prices.reduce((a: any, c: any) => {
          if (!a[c.templateId]) a[c.templateId] = [];
          a[c.templateId].push({
            edited: false,
            dozenValue: c.dozenValue,
            pricePerUnit: c.pricePerUnit,
            defaultValue: c.pricePerUnit,
          });
          return a;
        }, {});
        for (let t in _priceTable) {
          const k = _priceTable[t].templateId;
          if (_groupedPrices[k]) {
            _priceTable[t].prices = _groupedPrices[k].sort(
              (a: any, b: any) => a.dozenValue - b.dozenValue
            );
          }
        }
        const _templatePricing = templateIds.reduce((a: any, c: number) => {
          a[c] = generatePricing({ dozenWisePrice: _groupedPrices[c] });
          return a;
        }, {});

        const _itemStats: any = {};
        const _quantities: any = {};
        const _qty: any = {}
        _data?.purchaseOrders.forEach((po: any) =>
          po.orderItems.forEach((item: any) => {
            item.shipping = Number(
              (_shippingRate[item.templateId] * item.quantity).toFixed(2)
            );
            _quantities[item.id] = {};
            _qty[item.id] = {}
            item.price = Number(item.price);
            SIZES.forEach(
              (size) =>
                (_quantities[item.id][size] = {
                  edited: false,
                  value: item[`skuQty${size}`],
                  default: item[`skuQty${size}`],
                })
            );
            SIZES.forEach((s) => _qty[item.id][s] = item[`skuQty${s}`])
            const _saving = item.oldPrice - item.price;
            _itemStats[item.id] = {
              qty: {
                edited: false,
                value: item.quantity,
                default: item.quantity,
              },
              price: { edited: false, value: item.price, default: item.price },
              saving: { edited: false, value: _saving, default: _saving },
              shipping: {
                edited: false,
                value: item.shipping,
                default: item.shipping,
              },
            };
          })
        );
        const values = {
          id: _data.id,
          amountSubTotal: _data.amountSubTotal,
          amountDiscount: _data.amountDiscount,
          amountShipping: _data.amountShipping,
          amountTotal: _data.amountTotal,
          purchaseOrders: _data.purchaseOrders.map((_po: any) => ({
              id: _po.id,
              PO: _po.PO,
              addressId: _po.addressId,
              orderItems: _po.orderItems.map((_item: any) => {
                  return { 
                    id: _item.id,
                    skuQtyXS: _item.skuQtyXS,
                    skuQtyS: _item.skuQtyS,
                    skuQtyM: _item.skuQtyM,
                    skuQtyL: _item.skuQtyL,
                    skuQtyXL: _item.skuQtyXL,
                    skuQtyXXL: _item.skuQtyXXL,
                    quantity: _item.quantity,
                    oldPrice: _item.oldPrice,
                    price: _item.price,
                  }
              }),
              amountSubTotal: _po.amountSubTotal,
              amountDiscount: _po.amountDiscount,
              amountShipping: _po.amountShipping,
              amountTotal: _po.amountTotal,
          })),
          quantities: _qty
        }
        methods.reset(values)
        setDefaultValues(values)
        setData(_data);
        setLoading(false);
        setItemStats(_itemStats);
        setQuantities(_quantities);
        setShippingRate(_shippingRate);
        setTemplatePricing(_templatePricing);
        setPriceTable(_priceTable);
        setShippingAddrs(_shippingAddrs);
        setShippingTable(_shippingTable);
        setPOAndAddr(_POAndAddr);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };
    getData();
  }, [accessToken, id]);

  const handleUpdate = async () => {
    try {
      setUpdating(true);
      const payload: any = {
        amountSubTotal: orderStats.amountSubTotal,
        amountDiscount: orderStats.amountDiscount,
        amountShipping: orderStats.amountShipping,
        amountTotal: orderStats.amountTotal,
        purchaseOrders: data?.purchaseOrders.map((_po: any) => {
          return {
            id: _po.id,

            PO: POAndAddr[_po.id].PO,
            addressId: POAndAddr[_po.id].addressId,
            address1: POAndAddr[_po.id].address1,
            address2: POAndAddr[_po.id].address2,
            city: POAndAddr[_po.id].city,
            state: POAndAddr[_po.id].state,
            country: POAndAddr[_po.id].country,
            postalCode: POAndAddr[_po.id].postalCode,
            amountSubTotal: poStats[_po.id].amountSubTotal,
            amountDiscount: poStats[_po.id].amountDiscount,
            amountShipping: poStats[_po.id].amountShipping,
            amountTotal: poStats[_po.id].amountTotal,
            orderItems: _po.orderItems.map((_item: any) => {
              return {
                id: _item.id,

                ...(SIZES.reduce((a: any, c: any) => {
                  a[`skuQty${c}`] = Number(quantities[_item.id][c].value);
                  return a;
                }, {}) as any),

                sizeScale: SIZES.reduce((a: any, c: any) => {
                  a.push(quantities[_item.id][c].value);
                  return a;
                }, []).join(",") as any,
                oldPrice:
                  Number(itemStats[_item.id].price.value) +
                  Number(itemStats[_item.id].saving.value),
                price: itemStats[_item.id].price.value,
                quantity: itemStats[_item.id].qty.value,
              };
            }),
          };
        }),
      };
      // await updateOrder(accessToken, id, payload);
      setUpdating(false);
      toast.success("Order successfully updated.");
      setRefetch(true);
      onHide();
    } catch (err) {
      toast.error("Failed to update order.");
      setUpdating(false);
    }
  };

  const handleUpdateV2 = async (values: FormValues) => {
    console.log(values);
  };

  if (loading) return <SpinnerLoader />;

  return (
    <Modal
      className="custom-modal"
      show={open}
      onHide={onHide}
      aria-labelledby="custom-modal"
      backdrop="static"
      centered={true}
      size="xl"
    >
    
    <DefaultValueProvider value={defaultValues}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleUpdateV2)}>
          <Modal.Header>
            <h5 className="mb-0">Update Order {orderNumber}</h5>
          </Modal.Header>
          <Modal.Body>
            <Calculation data={data} templatePricing={templatePricing} shippingRate={shippingRate} />
            <Accordion defaultActiveKey="0">
              <Card className="mb-3">
                <Card.Header>Order Summary</Card.Header>
                <UpdateOrderSummary
                  data={data}
                />
              </Card>
              {data?.purchaseOrders.map((_po: any, i: number) => (
                <Card className="mb-1">
                  <Card.Header>
                    <POHeader
                      eventKey={`po_${_po.id}`}
                      PO={POAndAddr[_po.id].PO}
                      openPriceModal={togglePriceModal}
                      openShippingModal={toggleShippingModal}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey={`po_${_po.id}`}>
                    <UpdatePurchaseOrder
                      index={i}
                      key={_po.id}
                      _po={_po}
                      variantQty={variantQty}
                      stats={poStats[_po.id]}
                      quantities={quantities}
                      setQuantities={setQuantities}
                      itemStats={itemStats}
                      POAndAddr={POAndAddr}
                      setPOAndAddr={setPOAndAddr}
                      shippingAddrs={shippingAddrs}
                    />
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
            <UpdateItemPriceModal
              open={priceModal}
              onHide={togglePriceModal}
              priceTable={priceTable}
              setPriceTable={setPriceTable}
            />
            <UpdateItemShippingCostModal
              open={shippingModal}
              onHide={toggleShippingModal}
              shippingTable={shippingTable}
              setShippingTable={setShippingTable}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-outline-danger me-2"
                onClick={onHide}
                disabled={loading || updating}
              >
                Cancel
              </button>
              <button
                type="submit"
                // onClick={handleUpdate}
                className="btn btn-danger"
                disabled={loading || updating}
              >
                {updating ? "Updating..." : "Update"}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </FormProvider>
      </DefaultValueProvider>
    </Modal>
  );
};

export default UpdateOrder;
