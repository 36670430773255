import * as React from "react";
import { Stack } from "react-bootstrap";
import UpdateOrderItem from "./UpdateOrderItem";
import Editable from "./Editable";
import RHFEditable from "./RHFEditable";
import { Controller, useFormContext, useWatch } from "react-hook-form";

const UpdatePurchaseOrder = ({
  index,
  _po,
  variantQty,
  stats,
  quantities,
  setQuantities,
  itemStats,
  POAndAddr,
  setPOAndAddr,
  shippingAddrs,
}) => {
  const { control } = useFormContext();
  const [amountSubTotal, amountDiscount, amountShipping, amountTotal] = useWatch({
    control, name: [
      `purchaseOrders.${index}.amountSubTotal`,
      `purchaseOrders.${index}.amountDiscount`,
      `purchaseOrders.${index}.amountShipping`,
      `purchaseOrders.${index}.amountTotal`,
    ]
  })
  const handlePOChange = (v) => {
    setPOAndAddr((prev) => {
      const clone = { ...prev };
      clone[_po.id].PO = v;
      return clone;
    });
  };

  const handleAddrChange = (v) => {
    setPOAndAddr((prev) => {
      const clone = { ...prev };
      clone[_po.id].addressId = v;
      return clone;
    });
  };

  return (
    <Stack>
      <div style={{ marginInline: "20px", marginBlock: "10px" }}>
        <div className="d-flex justify-content-between align-items-center">
          <h5>Items:</h5>
          <span>
            PO: <RHFEditable fieldName={`purchaseOrders.${index}.PO`} />
          </span>
        </div>
        <hr />
        <label htmlFor="shipAddr">Ship To:</label>
        <div className="d-flex mt-1">
          <Controller
            control={control}
            name={`purchaseOrders.${index}.addressId`}
            render={({ field: { value, onChange, ref } }) => (
              <select
                id="shipAddr"
                className="form-select"
                onChange={onChange}
                value={value}
              >
                {shippingAddrs?.map((el) => (
                  <option value={el.id}>
                    {el.name}, {el.address1}, {el.address2}, {el.city},{" "}
                    {el.state}, {el.country}, {el.postalCode}
                  </option>
                ))}
              </select>
            )}
          />
        </div>
        <hr />
        <table
          style={{
            width: "100%",
            borderCollapse: "separate",
            borderSpacing: "0 8px",
          }}
        >
          <tr>
            <th style={{ width: "35%", paddingLeft: "25px" }}>Item</th>
            <th style={{ width: "35%" }}>Quantities</th>
            <th style={{ width: "15%", textAlign: "center" }}>Total Qty</th>
            <th style={{ width: "15%", textAlign: "center" }}>Total Price</th>
            <th style={{ width: "15%", textAlign: "center" }}>Shipping</th>
          </tr>
          {_po.orderItems?.map((item, i) => (
            <UpdateOrderItem
              key={item.id}
              poIndex={index}
              itemIndex={i}
              poId={_po.id}
              item={item}
              variantQty={
                variantQty[`${item.blankGarmentId}_${item.bodyColorAbbr}`]
              }
              quantities={quantities}
              setQuantities={setQuantities}
              itemStats={itemStats[item.id]}
            />
          ))}
        </table>
      </div>
      <div
        style={{
          marginTop: "20px",
          paddingBlock: "10px",
          paddingInline: "20px",
        }}
      >
        <h5>Summary:</h5>
        <hr />
        <p>
          <b>Sub Total:</b> ${amountSubTotal}
        </p>
        <p>
          <b>Discount:</b> ${amountDiscount}
        </p>
        <p>
          <b>Shipping:</b> ${amountShipping}
        </p>
        <p>
          <b>Total:</b> ${amountTotal}
        </p>
        {/* <p>Ship To: 32 Newton Town <i className='fa fa-pencil text-danger ms-3'></i></p> */}
      </div>
    </Stack>
  );
};

export default UpdatePurchaseOrder;
