export const DOZEN = 12

export const generatePricing = ({ dozenWisePrice }) => {
    dozenWisePrice.forEach((item) => item.pricePerUnit = Number(item.pricePerUnit))
    const sortedPriceArr = dozenWisePrice.sort((a, b) => a.dozenValue - b.dozenValue)

    const baseRate = sortedPriceArr?.[0]?.pricePerUnit
    const baseQty = sortedPriceArr?.[0]?.dozenValue * DOZEN

    const forQty = (qty) => {
        let rate = baseRate
        for (let item of sortedPriceArr) {
            const currQty = DOZEN * item.dozenValue
            if (currQty > qty) break
            rate = item.pricePerUnit
        }

        const mrp = Number((baseRate * qty).toFixed(2))
        const price = Number((rate * qty).toFixed(2))
        const saving = Number((mrp - price).toFixed(2))
        return {
            qty,
            rate,
            baseRate,
            mrp,
            price,
            saving,
            hasSaving: saving > 0
        }
    }

    return {
        baseQty,
        forQty
    }

}
//   U101 => tees
//   U102 => tees
//   U103 => tees
//   U105 => tees
//   U106 => banner
//   U107 => banner
//   U104 => polo
//   U401 => sweats
//   U402 => sweats

export const SHIPPING_COST = {
  U101: 0.75,
  U102: 0.75,
  U103: 0.75,
  U105: 0.75,
  U106: 0, // 'TBD',
  U107: 0, // 'TBD',
  U104: 0.75,
  U401: 2.25,
  U402: 2.25,
}

export const SHIPPING_TABLE = {
    U101: {
        edited: false,
        altCode: 'U101',
        value: 0.75,
        defaultValue: 0.75
    },
    U102: {
        edited: false,
        altCode: 'U102',
        value: 0.75,
        defaultValue: 0.75
    },
    U103: {
        edited: false,
        altCode: 'U103',
        value: 0.75,
        defaultValue: 0.75
    },
    U104: {
        edited: false,
        altCode: 'U104',
        value: 0.75,
        defaultValue: 0.75
    },
    U105: {
        edited: false,
        altCode: 'U105',
        value: 0.75,
        defaultValue: 0.75
    },
    U106: {
        edited: false,
        altCode: 'U106',
        value: 0.75,
        defaultValue: 0.75
    },
    U107: {
        edited: false,
        altCode: 'U107',
        value: 0.75,
        defaultValue: 0.75
    },
    U401: {
        edited: false,
        altCode: 'U401',
        value: 2.25,
        defaultValue: 2.25
    },
    U402: {
        edited: false,
        altCode: 'U402',
        value: 2.25,
        defaultValue: 2.25
    }
}

export const getValueByPath = (obj, path) => {
    const keys = path
      .split('.')
      .map((key) => {
        return key.includes('[') ? key.replace(/\[(\d+)\]/, '.$1') : key;
      })
      .join('.')
      .split('.');
  
    return keys.reduce((acc, key) => acc && acc[key], obj);
};