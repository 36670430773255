import * as React from 'react'
import { Modal } from 'react-bootstrap'
import Editable from './Editable';
import styles from './UpdateOrder.module.scss'

interface iProp {
    open: boolean;
    onHide: () => void;
    priceTable: any;
    setPriceTable: any;
}

const UpdateItemPriceModal: React.FC<iProp> = ({ open, onHide, priceTable, setPriceTable }): JSX.Element => {
    const rows = Object.values(priceTable)

    const handleChange = React.useCallback((val: string, edited: any, id: any, idx: number) => {
        setPriceTable((prevState: any) => {
            const clone = { ...prevState }
            clone[id].prices[idx].pricePerUnit = val
            clone[id].prices[idx].edited = edited
            return clone
        })
    }, [setPriceTable])

    return (
        <Modal
            className="custom-modal nestedModalL1"
            show={open}
            onHide={onHide}
            aria-labelledby="custom-modal"
            backdrop="static"
            centered={true}
            backdropClassName='nestedModalL1'
            size='lg'
        >
            <Modal.Header>
                Update Price
            </Modal.Header>
            <Modal.Body>
            <div style={{ marginTop: '-30px' }}>
                {rows.map((row: any, index: number) => (
                    <div style={{ paddingInline: '30px', marginTop: '50px' }}>
                        <h6>{row.altCode}-{row.b2bWebTitle} ({row.templateName})</h6>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th className={styles.pr_td_sr}>#</th>
                                    <th className={styles.pr_td}>Dozen</th>
                                    <th className={styles.pr_td}>Price/Unit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {row.prices?.map((el: any, i: number) => (
                                    <tr>
                                        <td className={styles.pr_td_sr}>{i + 1}</td>
                                        <td className={styles.pr_td}>{el.dozenValue}</td>
                                        <td className={styles.pr_td}>
                                            <Editable
                                                value={el.pricePerUnit}
                                                defaultValue={el.defaultValue}
                                                onChange={(v: any, e: boolean) => handleChange(v, e, row.templateId, i)}
                                                edited={el.edited}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-end">
                    <button
                        type="button"
                        className="btn btn-danger me-2"
                        onClick={onHide}
                    >
                        Close
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default UpdateItemPriceModal