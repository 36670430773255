import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import { getValueByPath } from './UpdateOrder.utils'
import { useDefaultValues } from './DefaultValueContext'

const RHFEditable: React.FC<any> = ({ fieldName, ...props }) => {
    const defaultValues = useDefaultValues()
    
    const { getValues, setValue, getFieldState, formState } = useFormContext()
    const [edit, setEdit] = React.useState(() => getFieldState(fieldName, formState).isDirty)
    const [fieldValue, setFieldValue] = React.useState<string>(() => getValues(fieldName))

    const handleEdit = () => setEdit(true)
    const handleReset = () => {
        const fieldValue = getValueByPath(defaultValues, fieldName)
        setValue(fieldName, fieldValue, { shouldDirty: false })
        setFieldValue(fieldValue)
        setEdit(false)
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(fieldName, e.target.value, { shouldDirty: true })
        setFieldValue(e.target.value)
    }

    return (
        <>

            {edit ? (
                <>
                    <input
                        className='ms-1'
                        value={fieldValue}
                        onChange={handleChange}
                        {...props}
                    />
                    <button type='button' className='icon-btn' onClick={handleReset}>
                        <i className='fa-solid fa-xmark text-danger'></i>
                    </button>
                </>
            ) : (
                <>
                    <span>{fieldValue}</span>
                    <button type='button' className='icon-btn' onClick={handleEdit}>
                        <i className='fa fa-pencil text-danger'></i>
                    </button>
                </>
            )}
        </>

    )
}

export default RHFEditable