import * as React from 'react'
interface IProp {
    value: any;
    children: React.ReactElement;
}

const context = React.createContext<any>(null)

const DefaultValueProvider: React.FC<IProp> = ({ value, children }): JSX.Element => {
  return (
    <context.Provider value={value}>
        {children}
    </context.Provider>
  )
}

export const useDefaultValues = () => React.useContext(context)

export default DefaultValueProvider