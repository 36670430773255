import * as React from 'react'
import { Card } from 'react-bootstrap'
import Editable from './Editable'
import styles from './UpdateOrder.module.scss'
import { useFormContext } from 'react-hook-form'

const UpdateOrderSummary = ({ data }) => {
    const { control, watch } = useFormContext() 
    const [amountSubTotal, amountDiscount, amountShipping, amountTotal] = watch(
      ["amountSubTotal", "amountDiscount", "amountShipping", "amountTotal"]
    );


    return (
        <Card.Body>
            <p><b>Ordered By:</b> {data?.userInfo.name} (<span className={styles.userEmail}>{data?.userInfo.email}</span>)</p>
            <p><b>Customer:</b> {data?.parentAccountName}</p>
            <p>
                <b>Subtotal:</b> ${amountSubTotal}
            </p>
            <p>
                <b>Discount:</b> ${amountDiscount}
            </p>
            <p>
                <b>Shipping:</b> ${amountShipping}
            </p>
            <p>
                <b>Total:</b> ${amountTotal}
            </p>
        </Card.Body>
    )
}

export default UpdateOrderSummary